@charset "utf-8";

/* Basic colors and fonts */
body {
	background: white;
	margin: 0px;
	padding: 20px;

	font-family: "Segoe UI", Verdana, Helvetica, Arial, sans-serif;
	font-size: 12pt;
}

.copyright {
	font-size: 70%;
	display: none;
}

#navigation ul, #navigation p {
	line-height: 1.5;
	list-style-type: none;
}

/* Mobile layout */
$twocol-min-width: 800px;

@media print, (max-width: $twocol-min-width - 1px) {
	#navigation {
		display: flex;
		align-items: center;
	}

	#navigation ul {
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	#navigation ul > li {
		margin-left: 11px;
	}

	#navigation img,
	#print-logo img {
		height: 2em;
		width: auto;
	}

	#copyright-1col {
		margin-top: 16px;
		display: block;
	}
}

/* Print layout */
#print-logo {
	display: none;
}

@media print {
	#navigation {
		display: none;
	}

	#print-logo {
		display: block;
		float: right;
	}
}

/* Two-column desktop layout */
@media screen and (min-width: $twocol-min-width) {
	#navigation {
		position: absolute;
		width: 200px;
		margin-right: 20px;
		border-right: 1px solid black;
		padding-right: 19px;
	}

	#navigation ul, #navigation p {
		line-height: 1.5;
		list-style-type: none;
		padding: 0;
		margin: 0 0 1em 11px;
	}

	#content {
		max-width: 800px;
		margin-left: 219px;
		border-left: 1px solid black;
		padding-left: 20px;
		padding-bottom: 1px;
	}

	#copyright-2col {
		display: block;
	}
}

/* Links */
a:link, a:visited {
	color: #375b84;
	text-decoration: none;
}
a:hover {
	text-decoration: underline;
}

a img {
	border: 0px;
}

/* Elements */
hr {
	border: none;
	border-bottom: 1px solid black;
}

h1 {
	margin-top: 0;
}
h1, h2, h3 {
	margin-bottom: .2em;
}
h1, article > header > h1 {
	font-size: 32px;
}
footer > .delta {
	font-size: 8pt;
}

p {
	margin-top: .2em;
}

td {
	vertical-align: top;
}

table {
	margin-bottom: 1em;
}

/* Syntax highlighting */
.highlight {
	.k, .kd, .nd { color: purple; };
	/*.nn { color: navy; };*/
	.p { color: green; };
	.c, .c1 { color: #444; };
	.s, .s1 { color: navy; };
}

pre, code.highlighter-rouge {
	tab-size: 2;
	-moz-tab-size: 2;
	background-color: #f0f0f0;
	padding: 0 2px;
	white-space: pre-wrap;
}

/* Break more generously on mobile */
@media (max-width: $twocol-min-width - 1px) {
	.highlight .p {
		overflow-wrap: anywhere;
	}
}

/* Helper classes */
ol.top, ul.top {
	margin-top: 0px;
}

.hang {
	text-indent: -3em; margin-left: 3em;
}

.sidebar {
	float: right;
	border: thin solid black;
	width: 200px;
	padding: 2px 2px 2px 2px;
}

.note, .error {
	padding: 5px 5px 5px 32px;
	/* border: 2px solid; */
	border-radius: 8px;
	background-position: 8px 8px;
	background-repeat: no-repeat;
}
.note {
	border-color: navy;
	background-color: lightblue;
	background-image: url(/images/silk/note.png);
}
.error {
	border-color: darkred; /*#cd1616;*/
	background-color: pink; /*#f8926f;*/
	background-image: url(/images/silk/error.png);
}
